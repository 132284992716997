export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "modalLicenceData": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence information"])},
        "status": {
          "notActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence is inactive"])},
          "isValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence is active"])},
          "isPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence is pending start date"])},
          "isExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence is expired"])},
          "isBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence suspended"])}
        },
        "sections": {
          "common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["common data"])},
          "contentPacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["content packs"])},
          "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tasks"])},
          "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assignment templates"])}
        },
        "fields": {
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
          "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])},
          "startedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid since"])},
          "endedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])},
          "maxDevicesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maximum number of devices"])},
          "devicesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["devices"])},
          "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tasks"])},
          "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assignment templates"])}
        }
      }
    }
  })
}
