<template>
	<vr-modal
		v-model:show="modalShow"
		@close="resetForm"
		size="small"
		align="center"
		footerAlign="center"
		class="add-device"
	>
		<template v-slot:header> {{ $t('title') }} </template>
		<template v-slot:body>
			<form class="form-data">
				<div class="form-group">
					<dropdown-select
						:options="licencesOptions"
						:selectedOption="selectedLicenceOption"
						:placeholder="$t('dropdown.placeholder')"
						@selectOption="selectLicence"
					/>

					<template v-if="!licenceSelected">
						<div class="add-device__instruction">
							<h3>
								<strong>{{ $t('instruction.title') }}</strong>
							</h3>
							<ol class="lead">
								<li>{{ $t('instruction.list.first') }}</li>
								<li>{{ $t('instruction.list.second') }}</li>
							</ol>
							<p class="text-muted">{{ $t('instruction.note.first') }}</p>
							<p class="text-muted">{{ $t('instruction.note.second') }}</p>
							<p class="text-muted">{{ $t('instruction.note.third') }}</p>
						</div>
					</template>
					<template v-else>
						<page-section :title="$t('licenceInfoTitle')">
							<page-section-field :label="$t(`fields.devicesNumber`)" :value="devicesNumberString" />
							<page-section-field :label="$t(`fields.availableKeys`)" :value="availableKeys" />
							<page-section-field :label="$t(`fields.key`)" :value="selectedLicence.key" />
							<div class="vr-modal-body__activation-keys">
								<input
									v-model="codesNumber"
									type="text"
									class="form-control"
									:placeholder="$t('codesNumber')"
								/>
								<button
									@click.prevent="createCodes"
									:disabled="createCodesBtnDisabled"
									class="btn btn-success"
								>
									{{ $t('createActivationCodes') }}
								</button>
							</div>
							<page-section-list :title="$t('activationCodesTitle')" :list="activationCodes" />
						</page-section>
					</template>
				</div>
			</form>
		</template>
		<template v-slot:footer>
			<button v-if="licenceSelected" @click.prevent="updateLicence" class="btn btn-primary">
				{{ $t('refreshBtn') }}
			</button>
		</template>
	</vr-modal>
</template>

<script>
import dayjs from 'dayjs';
import { QueryError } from '@/errors';
import { toastMixin } from '@/mixins';
import DropdownSelect from '@/components/dropdowns/DropdownSelect.vue';
import VrModal from '@/components/modals/VrModal.vue';
import PageSection from '@/components/page-sections/PageSection.vue';
import PageSectionField from '@/components/page-sections/PageSectionField.vue';
import PageSectionList from '@/components/page-sections/PageSectionList.vue';
import MYCOMPANY from '@/queries/views/devices/query-company-licences.graphql';
import REQUESTCODES from '@/queries/views/devices/mutation-create-codes.graphql';
import LICENCE from '@/queries/views/devices/query-company-licence.graphql';

export default {
	emits: ['update:show'],
	mixins: [toastMixin],
	data() {
		return {
			licences: [],
			queryError: null,
			selectedLicence: { id: null, label: '---' },
			codesNumber: null,
		};
	},
	components: {
		VrModal,
		DropdownSelect,
		PageSection,
		PageSectionField,
		PageSectionList,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		resetForm() {
			this.selectedLicence = { id: null, label: '---' };
			this.codesNumber = null;
		},
		closeModal() {
			this.modalShow = false;
			this.resetForm();
		},
		async updateLicence() {
			const { data } = await this.$apollo.query({
				query: LICENCE,
				fetchPolicy: 'network-only',
				variables: { licenceId: this.selectedLicence.id, fieldFilter: { isValid: true } },
			});

			this.selectedLicence = {
				...data.licence,
				label: data.licence?.endedAt
					? `${this.$t('endedAt')} ${dayjs(data.licence.endedAt).format('DD.MM.YYYY')}`
					: '',
			};
		},
		selectLicence(licenceId) {
			licenceId === null
				? (this.selectedLicence = { id: null, label: '---' })
				: (this.selectedLicence = this.licences.find(el => el.id === licenceId));
		},
		async createCodes() {
			try {
				const { data } = await this.$apollo.mutate({
					mutation: REQUESTCODES,
					variables: { codesNumber: +this.codesNumber, licenceId: this.selectedLicence.id },
				});
				if (data.requestActivationCodes.success) {
					await this.updateLicence();
				} else {
					throw new QueryError(data.requestActivationCodes.error);
				}
			} catch (err) {
				let errMsg;
				if (err instanceof QueryError) {
					errMsg = `errors.${err.cause.type}`;
				} else {
					errMsg = 'errors.FallbackError';
				}
				this.handleError(err, errMsg);
			}
		},
		handleError(err, msg) {
			if (msg === 'errors.ValueOutOfBoundsError') {
				this.showToast('errors.ValueOutOfBounds', '', 'danger');
			}
		},
	},
	computed: {
		modalShow: {
			get() {
				return this.show;
			},
			set(show) {
				this.$emit('update:show', show);
			},
		},
		filteredLicences() {
			return this.licences.filter(el => el.availableDevicesNumber > 0);
		},
		licencesOptions() {
			let res = this.filteredLicences.map(el => ({
				id: el.id,
				label: `${this.$t('endedAt')} ${dayjs(el.endedAt).format('DD.MM.YYYY')}`,
			}));
			res.unshift({ id: null, label: '---' });
			return res;
		},
		selectedLicenceOption() {
			if (this.selectedLicence.id === null) {
				return this.selectedLicence;
			}
			return {
				id: this.selectedLicence.id,
				label: `${this.$t('endedAt')} ${dayjs(this.selectedLicence.endedAt).format('DD.MM.YYYY')}`,
			};
		},
		licenceSelected() {
			return !!this.selectedLicence.id;
		},
		devicesNumber() {
			return this.selectedLicence.maxDevicesNumber - this.selectedLicence.availableDevicesNumber;
		},
		devicesNumberString() {
			return `${this.devicesNumber} (${this.selectedLicence.maxDevicesNumber})`;
		},
		createCodesBtnDisabled() {
			return !/^\+?(0|[1-9]\d*)$/.test(this.codesNumber);
		},
		activationCodes() {
			return this.selectedLicence?.activationCodes.map(el => el.value) ?? [];
		},
		availableKeys() {
			return this.selectedLicence.maxDevicesNumber - this.devicesNumber - this.activationCodes.length;
		},
	},
	apollo: {
		myCompany: {
			query: MYCOMPANY,
			manual: true,
			variables() {
				return { licenceFilter: { isValid: true }, activationCodeFilter: { isValid: true } };
			},
			result({ loading, data, errors }) {
				if (!loading) {
					this.licences = data.myCompany.licences;
				}
			},
		},
	},
};
</script>

<i18n locale="ru" src="@/locales/ru/components/modals/modal-add-device.json"></i18n>
<i18n locale="en" src="@/locales/en/components/modals/modal-add-device.json"></i18n>

<style lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.add-device {
	.vr-modal-body {
		&__activation-keys {
			display: flex;
			margin-bottom: 3rem;
			input {
				margin-right: 1rem;
			}
		}
	}

	&__instruction {
		padding: 1rem;
		border: 1px solid $gray-400;

		p {
			padding-left: 2rem;
			margin-bottom: 0.5rem;
		}
	}
}
</style>
