export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти роль"])},
      "defaultRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль по умолчанию"])},
      "createRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новую роль"])},
      "roleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название роли"])},
      "deleteRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить роль"])},
      "setDefaultRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначить ролью по умолчанию"])},
      "saveRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
      "defaultRoleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая роль"])},
      "confirmDeleteHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание!"])},
      "confirmDeleteBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль будет удалена безвозвратно. Вы уверены, что хотите удалить эту роль?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно!"])},
      "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль сохранена."])},
      "setDefaultSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль по умолчанию выбрана."])},
      "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль удалена."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка!"])},
      "errors": {
        "NameTooLongError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превышена максимально допустимая длина названия роли (100 символов)."])},
        "EmptyValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название роли."])},
        "UniqueValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль с таким названием уже существует."])},
        "UnauthorizedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не вошли в систему."])},
        "UserPermissionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет разрешения на совершение этой операции."])},
        "NotFoundError": {
          "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация не найдена."])},
          "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль не найдена."])},
          "Permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешение не найдено."])}
        },
        "CompanyMismatchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно выполнить операцию над ролью, принадлежащей к другой организации."])},
        "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При выполнении операции произошла ошибка. Пожалуйста, попробуйте позже."])}
      }
    }
  })
}
