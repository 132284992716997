export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание задачи"])},
      "fields": {
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["теги"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["название"])},
        "annotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["аннотация"])},
        "passingGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["проходной балл"])}
      },
      "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
      "cancelBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "validation": {
        "name": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется указать название задачи"])},
          "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Длина названия задачи не должна превышать значение в ", _interpolate(_named("nameLength")), " символов"])}
        },
        "passingGrade": {
          "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Проходной балл должен быть целым числом в диапазоне от 0 до ", _interpolate(_named("gradeVal"))])}
        }
      },
      "query": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача создана успешно."])},
        "errors": {
          "UniqueValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача с таким названием в организации уже существует."])},
          "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка соединения. Попробуйте позже."])},
          "CompanyTaskManageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вашей организации нет разрешения на работу с задачами."])},
          "EntityLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша организация достигла максимально допустимого количества задач."])}
        }
      }
    }
  })
}
