export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task information"])},
      "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vics studio"])},
      "warnings": {
        "taskFromLicence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This task is available via a licence and can not be changed except for adding or removing tags."])}
      },
      "sections": {
        "common": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["common data"])},
          "fields": {
            "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tags"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
            "annotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annotation"])},
            "passingGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passing grade"])},
            "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["author"])},
            "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creation date"])}
          }
        },
        "status": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
          "fields": {
            "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["draft"])},
            "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["published"])},
            "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archived"])}
          }
        }
      },
      "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
      "cancelBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "tips": {
        "draft": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A draft task is a work in progress, unavailable for assignment until it is published."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Status of published task can't be change to \"Draft\" in case when this task is a part of one or several assignment templates."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Published task can not be reverted to draft if it is a part of at least one assignment template, licence or content pack."])}
        },
        "publication": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A published task becomes available to be run by other users."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! Before publishing your task, ensure that it is complete. You will not be able to revert a publication if a task becomes a part of an assignment template."])}
        },
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An archive task is only available as a part of assignment templates created before it was archived. It can not be used in any new ones."])}
      },
      "validation": {
        "name": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter task name"])},
          "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Task name length must not exceed ", _interpolate(_named("nameLength")), " characters."])}
        },
        "passingGrade": {
          "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Passing grade must be an integer between 0 and ", _interpolate(_named("gradeVal")), "."])}
        }
      },
      "query": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task update successful."])},
        "errors": {
          "NotFoundError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task not found."])},
          "UniqueValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another task with this name already exists within your organisation."])},
          "PublishEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not un-publish this task. It is being used in an assignment template."])},
          "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])},
          "CompanyTaskManageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your organisation does not have permission to manage tasks."])},
          "MutationPublishedEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published task can't be changed."])}
        }
      }
    }
  })
}
