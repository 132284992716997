export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация об устройстве"])},
      "status": {
        "isValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устройство активно"])},
        "isInvalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Устройство неактивно (", _interpolate(_named("licenceStatus")), ")"])}
      },
      "licenceStatus": {
        "notActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лицензия деактивирована"])},
        "isValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лицензия активна"])},
        "isPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лицензия ожидает срока начала действия"])},
        "isExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лицензия истекла"])},
        "isBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лицензия заблокирована"])}
      },
      "sections": {
        "common": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["общее"])},
          "fields": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["имя устройства"])},
            "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["модель"])}
          }
        },
        "licence": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лицензия"])},
          "fields": {
            "startedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["действует с"])},
            "endedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["действует до"])},
            "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лицензионный ключ"])}
          }
        }
      },
      "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
      "cancelBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "validation": {
        "name": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется указать имя устройства"])},
          "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Длина имени устройства не должна превышать значение в ", _interpolate(_named("nameLength")), " символов"])}
        }
      },
      "query": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устройство успешно обновлено."])},
        "errors": {
          "NotFoundError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Указанное устройство не найдено. Обновите страницу со списком устройств."])},
          "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка соединения. Попробуйте позже."])}
        }
      }
    }
  })
}
