export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о задаче"])},
      "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vr chemistry lab"])},
      "warnings": {
        "taskFromLicence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта задача доступна по лицензии. В неё нельзя внести изменения помимо изменения и добавления тегов."])}
      },
      "sections": {
        "common": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["общее"])},
          "fields": {
            "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["теги"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["название"])},
            "annotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["аннотация"])},
            "passingGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["проходной балл"])},
            "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["автор"])},
            "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["создана"])}
          }
        },
        "status": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["статус"])},
          "fields": {
            "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["черновик"])},
            "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["опубликована"])},
            "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["архивирована"])}
          }
        }
      },
      "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
      "cancelBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "tips": {
        "draft": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черновик - задача, над которой всё ещё ведётся работа. Она не доступна для назначения пользователям."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Статус опубликованной задачи не может быть изменён на \"Черновик\" в случае, если задача используется в одном или нескольких шаблонах заданий."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Статус опубликованной задачи не может быть изменён на \"Черновик\" в случае, если задача входит в состав хотя бы одного шаблона задания, контент пака или лицензии."])}
        },
        "publication": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликованная задача становится доступной для запуска в лаборатории для других пользователей организации."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание! Перед публикацей задачи убедитесь, что работа над ней полностью закончена. Отмена публикации возможна только в случае, если задача ещё не была добавлена в какие-либо шаблоны заданий."])}
        },
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивную задачу нельзя использовать для создания новых шаблонов заданий. Архивная задача доступна для прохождения только в рамках тех заданий, что были созданы с её участием до момента архивации."])}
      },
      "validation": {
        "name": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется указать название задачи"])},
          "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Длина названия задачи не должна превышать значение в ", _interpolate(_named("nameLength")), " символов"])}
        },
        "passingGrade": {
          "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Проходной балл должен быть целым числом в диапазоне от 0 до ", _interpolate(_named("gradeVal"))])}
        }
      },
      "query": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача обновлена успешно."])},
        "errors": {
          "NotFoundError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Указанная задача не найдена. Обновите страницу со списком задач."])},
          "UniqueValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача с таким названием в организации уже существует."])},
          "PublishEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно отменить публикацию. Задача используется в заданиях организации."])},
          "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка соединения. Попробуйте позже."])},
          "CompanyTaskManageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вашей организации нет разрешения на работу с задачами."])},
          "MutationPublishedEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нельзя редактировать опубликованную задачу."])}
        }
      }
    }
  })
}
