<template>
	<div :class="['vr-sidebar', { 'vr-sidebar--collapsed': collapsed }]">
		<div class="vr-sidebar__header">
			<button class="vr-sidebar__header__button" @click="toggleCollapse">
				<fa-icon :icon="iconState" size="lg" />
			</button>
			<span class="vr-sidebar__header__title">{{ $t('productName') }}</span>
		</div>
		<div ref="sidebarBody" class="vr-sidebar__body">
			<template v-for="(section, idx) in sections" :key="`sidebar-body-section-${idx}`">
				<sidebar-section v-if="section.canView" :section="section" />
			</template>
		</div>
	</div>
</template>

<i18n locale="ru" src="@/locales/ru/components/sidebar.json"></i18n>
<i18n locale="en" src="@/locales/en/components/sidebar.json"></i18n>

<script>
import SidebarSection from './SidebarSection';

import userPermissionsMixin from '@/mixins/permissions/userPermissions.js';
import taskPermissionsMixin from '@/mixins/permissions/taskPermissions.js';
import licencePermissionsMixin from '@/mixins/permissions/licencePermissions.js';
import devicePermissionsMixin from '@/mixins/permissions/devicePermissions.js';
import assignmentPermissionsMixin from '@/mixins/permissions/assignmentPermissions.js';
import assignmentForUserPermissionsMixin from '@/mixins/permissions/assignmentForUserPermissions.js';
import companyPermissionsMixin from '@/mixins/permissions/companyPermissions.js';
import { rolePermissionsMixin, userAgreementUrlMixin } from '@/mixins';

export default {
	mixins: [
		userPermissionsMixin,
		taskPermissionsMixin,
		licencePermissionsMixin,
		devicePermissionsMixin,
		assignmentPermissionsMixin,
		assignmentForUserPermissionsMixin,
		rolePermissionsMixin,
		companyPermissionsMixin,
		userAgreementUrlMixin,
	],
	data() {
		return {
			collapsed: false,
		};
	},
	methods: {
		toggleCollapse() {
			this.collapsed = !this.collapsed;
		},
	},
	computed: {
		iconState() {
			let icon = 'fa-solid';
			if (!this.collapsed) return icon + ' fa-bars';
			return icon + ' fa-bars-staggered';
		},
		sections() {
			return [
				{
					label: this.$t('sections.user.label'),
					canView: true,
					links: [
						{
							to: 'UserAssignments',
							label: this.$t('sections.user.links.assignments'),
							icon: 'fas fa-clipboard-list',
							canView: true,
						},
						{
							to: 'UserSettings',
							label: this.$t('sections.user.links.settings'),
							icon: 'fa-solid fa-wrench',
							canView: true,
						},
					],
				},
				{
					label: this.$t('sections.company.label'),
					canView: true,
					links: [
						{
							to: 'Assignments',
							label: this.$t('sections.company.links.assignments'),
							icon: 'fas fa-clipboard-list',
							canView: this.canViewAssignmentForUser,
						},
						{
							to: 'AssignmentTemplates',
							label: this.$t('sections.company.links.assignmentTemplates'),
							icon: 'fas fa-file-invoice',
							canView: this.canViewAssignment,
						},
						{
							to: 'CompanyTasks',
							label: this.$t('sections.company.links.tasks'),
							icon: 'fa-solid fa-book-open',
							canView: this.canViewTask,
						},
						{
							to: 'Users',
							label: this.$t('sections.company.links.users'),
							icon: 'fas fa-user-group',
							canView: this.canViewUser,
						},
						{
							to: 'Roles',
							label: this.$t('sections.company.links.roles'),
							icon: 'fas fa-users-rectangle',
							canView: this.canViewRole,
						},
						{
							to: 'CompanyDevices',
							label: this.$t('sections.company.links.devices'),
							icon: 'fa-solid fa-vr-cardboard',
							canView: this.canViewDevice,
						},
						{
							to: 'CompanyLicences',
							label: this.$t('sections.company.links.licences'),
							icon: 'fa-solid fa-clipboard',
							canView: this.canViewLicence,
						},
						{
							to: 'CompanySettings',
							label: this.$t('sections.company.links.settings'),
							icon: 'fa-sold fa-gear',
							canView: this.canChangeCompany,
						},
					],
				},
				{
					label: this.$t('sections.info.label'),
					canView: true,
					links: [
						{
							to: 'About',
							label: this.$t('sections.info.links.about'),
							icon: 'fa-solid fa-circle-question',
							canView: true,
						},
						{
							to: this.userAgreementUrl,
							label: this.$t('sections.info.links.userAgreement'),
							icon: 'fa-solid fa-circle-question',
							canView: this.canChangeCompany,
						},
					],
				},
			];
		},
	},
	components: {
		SidebarSection,
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.vr-sidebar {
	overflow-y: auto;
	grid-area: sidebar;
	width: 14.5rem;
	height: 100vh;
	padding: 0 0.5rem 0 0.5rem;
	border-right: 1px solid $gray-300;
	transition: width 0.25s;

	&--collapsed {
		padding: 0 1rem 0 1rem;
		width: 4rem;
		padding: 0;

		.vr-sidebar {
			&__header {
				display: flex;
				justify-content: center;

				& > span {
					display: none;
				}
			}

			&__body__section ::v-deep(.vr-sidebar__body__section__navlink > div) {
				padding: 0.5rem 0;
			}

			&__body__section ::v-deep(.vr-sidebar__body__section__navlink > div > svg) {
				display: block;
				margin: 0 auto;
			}

			&__body__section ::v-deep(.vr-sidebar__body__section__header__title),
			&__body__section ::v-deep(.vr-sidebar__body__section__navlink > div > span) {
				display: none;
			}

			&__body__section ::v-deep(.vr-sidebar__body__section__header__separator) {
				margin-left: 0;
			}
		}
	}

	&__header {
		display: flex;
		align-items: center;
		margin-top: 0.5rem;
		padding: 0.5rem;
		height: 3.375rem;

		&__button {
			background: none;
			color: inherit;
			border: none;
			padding: 0;
			font: inherit;
			cursor: pointer;
			outline: inherit;
		}

		&__title {
			margin-left: 1.5rem;
			font-size: $small-font-size;
		}
	}
}
</style>
