export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "titleCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление пользователя"])},
      "titleEditSingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование пользователя"])},
      "titleEditMultiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование пользователей"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание! Все изменения, которые вы внесёте сейчас, коснутся нескольких пользователей одновременно."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
      "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пин-код"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роли"])},
      "rolesHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роли всех пользователей будут заменены на выбранные выше."])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])},
      "tagsHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги всех пользователей будут заменены на выбранные выше."])},
      "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечания"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
      "confirmCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
      "confirmEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
      "errors": {
        "EmailFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес электронной почты имеет неправильный формат."])},
        "EmailExistsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес электронной почты уже используется другим пользователем."])},
        "EmailRequiredError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес электронной почты должен быть указан"])},
        "PasswordTooShortError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длина пароля не должна быть меньше 8 символов."])},
        "SameAsEmailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не должен совпадать с адресом электронной почты."])},
        "PinLengthError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длина пин-кода должна составлять 4 символа."])},
        "PinFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пин-код может содержать только цифры."])},
        "PasswordMismatchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли должны совпадать."])}
      }
    }
  })
}
