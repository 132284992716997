export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "emptyTablePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас ещё нет устройств"])},
      "actions": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--- Действие ---"])},
        "bulkDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отвязать"])}
      },
      "filters": {
        "showValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активные устройства"])},
        "showInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивные устройства"])}
      },
      "devicesTable": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список устройств"])},
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя устройства"])},
          "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модель"])},
          "isValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])}
        },
        "unrecognisedDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["неизвестно"])}
      },
      "bindDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязка устройств"])},
      "bulkDelete": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отвязка устройств"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В результате действия будут отвязаны выбранные устройства. Для продолжения нажмите \"Подтвердить\"."])}
      },
      "query": {
        "bulkDeleteDevices": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устройства успешно отвязаны"])},
          "errors": {
            "NotFoundError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одно или несколько устройств не найдены. Попробуйте обновить страницу."])},
            "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка соединения. Попробуйте позже."])}
          }
        }
      }
    }
  })
}
