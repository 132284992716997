export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "timeLimits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сроки"])},
      "timeLimitStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало выполнения"])},
      "timeLimitEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Окончание выполнения"])},
      "assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задание"])},
      "accessExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ к заданию закончится"])},
      "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порядок прохождения"])},
      "orderSeq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["последовательный"])},
      "orderArb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["произвольный"])},
      "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи"])},
      "passingGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проходной балл"])},
      "allUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все пользователи"])},
      "selectedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбранные пользователи"])},
      "assignAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначить задание"])},
      "updateAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
      "deleteAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "dragUsersHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перетащите пользователей сюда"])},
      "selectAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите шаблон задания"])},
      "undefiendEndedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дата не определена"])},
      "validations": {
        "AssignmentNotSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон задания не выбран."])},
        "UsersNotSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи не выбраны."])},
        "EndedAtIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задание должно иметь дату окончания выполнения."])},
        "EndedAtIsEarlyStartedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время окончания задания должны быть позже времени его начала."])},
        "EndedAtIsLaterEndedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания выполнения задания не должна быть позднее даты окончания доступа к заданию."])}
      },
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
      "errors": {
        "ValueOutOfBoundsError": {
          "startedAt": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("validations.EndedAtIsEarlyStartedAt", undefined, _type)])},
          "endedAt": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("validations.EndedAtIsLaterEndedAt", undefined, _type)])}
        },
        "NotAvailableTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбранный шаблон задания использует недоступные вашей организации в данный момент задачи."])},
        "UnpublishedTaskUsageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбранный шаблон задания использует неопубликованные задачи."])},
        "NotActiveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среди выбранных пользователей есть неактивные."])},
        "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка на сервере. Пожалуйста, попробуйте позже."])}
      },
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно"])},
      "successText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задание успешно назначено пользователям"])},
      "tagsFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтровать по тегам"])},
      "searchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по email"])}
    }
  })
}
