<template>
	<div class="page-content tasks-page">
		<spinner :show="$apollo.loading" />
		<h5 v-if="!tasks.length && !$apollo.loading" class="display-5 tasks-page__placeholder">
			{{ $t('emptyTablePlaceholder') }}
		</h5>
		<div v-if="!canManageTasks" class="alert alert-danger alert-manage-tasks" role="alert">
			<fa-icon icon="fa-solid fa-circle-exclamation" class="icon-false" />
			<h6>
				{{ $t('cantManageTasks') }}
			</h6>
		</div>

		<div v-show="taskCount >= taskLimit" class="alert alert-danger alert-manage-tasks" role="alert">
			<fa-icon icon="fa-solid fa-circle-exclamation" class="icon-false" />
			<h6>
				{{ $t('taskLimitReached') }}
			</h6>
		</div>
		<data-table
			:headers="headers"
			:items="items"
			checkboxes
			:selected="selectedTasks"
			:defaultSort="{ field: 'name', type: 'string', direction: 'desc' }"
			@activateItem="openTaskData"
			@selectedItems="setSelected"
		>
			<template #header>
				<div class="tasks-page__table-header">
					<div class="task-dropdowns">
						<dropdown-list
							icon="fa-solid fa-filter"
							:list="Object.keys(filters)"
							v-model:selection="filters"
							:i18n="filtersI18n"
						/>
						<dropdown v-if="canMakeActions" :disabled="!canManageTasks">
							<template #button>
								{{ $t('actions.placeholder') }}
							</template>

							<template #default="{ close }">
								<template v-for="(action, idx) in displayedActions" :key="`action-${idx}`">
									<li>
										<button
											type="button"
											class="dropdown-item"
											@click.stop="handleAction(action.value, close)"
											:disabled="!selectedTasks.length"
										>
											<span>{{ action.label }}</span>
										</button>
									</li>
								</template>
							</template>
						</dropdown>
					</div>
					<div class="alert alert-info mb-0 py-2 task-count-limit">
						<span>{{ $t('taskCountLimit.label', { taskCount, taskLimit }) }}</span>
						<popper hover arrow placement="left">
							<fa-icon icon="fa-regular fa-circle-question" />
							<template #content>
								<p>
									{{ $t('taskCountLimit.tips.one') }}
								</p>
								<p>{{ $t('taskCountLimit.tips.two') }}</p>
							</template>
						</popper>
					</div>
					<div class="task-tag-filter">
						<label>{{ $t('tagsFilter') }}</label>
						<tag-list :all-tags="tagsList" v-model:tags="selectedTags" editable />
					</div>
					<div class="task-search-and-button">
						<div class="search-task-by-name">
							<label>{{ $t('searchTask') }}</label>
							<input class="form-control form-control-sm" type="text" v-model="searchTask" />
						</div>
						<button
							v-if="canAddTask"
							@click.stop="openTaskCreate"
							class="btn btn-success task-add-btn"
							:disabled="!canManageTasks || taskCount >= taskLimit"
						>
							{{ $t('createTaskBtn') }}
						</button>
					</div>
				</div>
			</template>
			<template #item.name="{ item }">
				<div class="task-name">
					<div
						:class="[
							'task-icon',
							{ 'task-icon--is-foreign': item.availableFrom === 'LICENCE' && !(isSuperuser || isStaff) },
						]"
					>
						<fa-icon
							v-if="item.availableFrom === 'LICENCE' && !(isSuperuser || isStaff)"
							icon="fas fa-clipboard"
						/>
					</div>
					<span> {{ item.name }} </span>
				</div>
			</template>
			<template #item.status="{ item }">
				<div :class="['badge', getStatusBadge(item.status).style]">
					{{ getStatusBadge(item.status).label }}
				</div>
			</template>
			<template #item.tags="{ item }">
				<tag-list v-model:tags="item.tags" />
			</template>
		</data-table>
		<modal-task-data v-model:show="showTaskData" :task="openedTask" v-model:tags="tags" />
		<modal-add-task v-model:show="showTaskCreate" v-model:tags="tags" @createTask="addCreatedTask" />

		<modal-confirm v-model:show="showConfirm" @confirm="bulkDelete">
			<template #header>{{ $t('bulkDeleteConfirm.header') }}</template>
			<template #body>{{ $t('bulkDeleteConfirm.body') }}</template>
		</modal-confirm>
	</div>
</template>

<i18n locale="ru" src="@/locales/ru/views/tasks.json"></i18n>
<i18n locale="en" src="@/locales/en/views/tasks.json"></i18n>

<script>
import { QueryError } from '@/errors';
import Pagination from '@/components/Pagination.vue';
import DataTable from '@/components/DataTable.vue';
import Dropdown from '@/components/dropdowns/Dropdown.vue';
import DropdownList from '@/components/dropdowns/DropdownList.vue';
import ModalTaskData from '@/components/modals/ModalTaskData.vue';
import ModalAddTask from '@/components/modals/ModalAddTask.vue';
import ModalConfirm from '@/components/modals/ModalConfirm.vue';
import Spinner from '@/components/Spinner.vue';
import TagList from '@/components/TagList.vue';

import TASKS from '@/queries/views/tasks/query-company-tasks.graphql';
import BULK_COPY_TASKS from '@/queries/views/tasks/mutation-bulk-copy-tasks.graphql';
import BULK_UPDATE_TASKS from '@/queries/views/tasks/mutation-bulk-update-tasks.graphql';
import BULK_DELETE_TASKS from '@/queries/views/tasks/mutation-bulk-delete-tasks.graphql';

import toastMixin from '@/mixins/toast.js';
import taskPermissionsMixin from '@/mixins/permissions/taskPermissions.js';
import { mapState, mapGetters } from 'vuex';

export default {
	mixins: [toastMixin, taskPermissionsMixin],
	data() {
		return {
			tasks: [],
			openedTask: [],
			selectedTasks: [],
			tags: [],
			selectedTags: [],
			actions: [
				{
					value: 'bulkCopy',
					label: this.$t('actions.bulkCopy'),
				},
				{
					value: 'bulkArchive',
					label: this.$t('actions.bulkArchive'),
				},
				{
					value: 'bulkRestore',
					label: this.$t('actions.bulkRestore'),
				},
				{
					value: 'bulkDelete',
					label: this.$t('actions.bulkDelete'),
				},
			],
			filters: {
				licenced: true,
				company: true,
				draft: true,
				published: true,
				archived: true,
			},
			headers: [
				{ text: this.$t('taskTable.headers.name'), field: 'name', type: 'string' },
				{ text: this.$t('taskTable.headers.author'), field: 'author.email', type: 'string' },
				{ text: this.$t('taskTable.headers.status'), field: 'status', type: 'string' },
				{ text: this.$t('taskTable.headers.tags'), field: 'tags' },
			],
			showTaskData: false,
			showTaskCreate: false,
			showConfirm: false,
			taskLimit: 1,
			searchTask: '',
		};
	},
	methods: {
		setSelected(tasks) {
			this.selectedTasks = tasks;
		},
		openTaskCreate() {
			this.showTaskCreate = true;
		},
		openTaskData(index) {
			this.openedTask = this.tasks.find(el => el.id === index);
			this.showTaskData = true;
		},
		addCreatedTask(task) {
			this.tasks.push({ ...task });
		},

		getStatusBadge(status) {
			switch (status) {
				case 'DRAFT':
					return { style: 'text-bg-secondary', label: this.$t('taskTable.items.statuses.draft') };
				case 'PUBLISHED':
					return { style: 'text-bg-success', label: this.$t('taskTable.items.statuses.published') };
				case 'ARCHIVED':
					return { style: 'text-bg-warning', label: this.$t('taskTable.items.statuses.archived') };
			}
		},
		async bulkCopy() {
			try {
				const { data } = await this.$apollo.mutate({
					mutation: BULK_COPY_TASKS,
					variables: { tasks: this.selectedTasks.map(el => +el.id) },
				});
				if (data.bulkCopyTasks.success) {
					this.tasks.push(...data.bulkCopyTasks.tasks);
					this.selectedTasks = [];
					this.showToast('query.success.bulkCopy', '', 'success');
				} else {
					throw new QueryError(data.bulkCopyTasks.error);
				}
			} catch (err) {
				let errType = 'FallbackError';
				if (err instanceof QueryError) {
					errType = err.cause.type;
				}
				this.showToast(`query.errors.${errType}`, '', 'danger');
			}
		},
		async bulkArchive() {
			try {
				const { data } = await this.$apollo.mutate({
					mutation: BULK_UPDATE_TASKS,
					variables: {
						tasks: this.selectedTasks.map(el => el.id),
						data: { status: 'ARCHIVED' },
					},
				});
				if (data.bulkUpdateTasks.success) {
					this.tasks = this.tasks.filter(task => !this.selectedTasks.some(t => t.id === task.id));
					this.tasks.push(...data.bulkUpdateTasks.tasks);
					this.selectedTasks = [];
					this.showToast('query.success.bulkArchive', '', 'success');
				} else {
					throw new QueryError(data.bulkUpdateTasks.error);
				}
			} catch (err) {
				let errType = 'FallbackError';
				if (err instanceof QueryError) {
					errType = err.cause.type;
				}
				this.showToast(`query.errors.${errType}`, '', 'danger');
			}
		},
		async bulkRestore() {
			try {
				const { data } = await this.$apollo.mutate({
					mutation: BULK_UPDATE_TASKS,
					variables: {
						tasks: this.selectedTasks.map(el => el.id),
						data: { status: 'PUBLISHED' },
					},
				});
				if (data.bulkUpdateTasks.success) {
					this.tasks = this.tasks.filter(task => !this.selectedTasks.some(t => t.id === task.id));
					this.tasks.push(...data.bulkUpdateTasks.tasks);
					this.selectedTasks = [];
					this.showToast('query.success.bulkRestore', '', 'success');
				} else {
					throw new QueryError(data.bulkUpdateTasks.error);
				}
			} catch (err) {
				let errType = 'FallbackError';
				if (err instanceof QueryError) {
					errType = err.cause.type;
				}
				this.showToast(`query.errors.${errType}`, '', 'danger');
			}
		},
		async bulkDelete() {
			try {
				const toDelete = this.selectedTasks;
				const { data } = await this.$apollo.mutate({
					mutation: BULK_DELETE_TASKS,
					variables: { tasks: this.selectedTasks.map(el => +el.id) },
					update(cache, { data }) {
						toDelete.forEach(t => cache.evict(cache.identify(t)));
						cache.gc();
					},
				});
				if (data.bulkDeleteTasks.success) {
					this.tasks = this.tasks.filter(task => !this.selectedTasks.some(t => t.id === task.id));
					this.selectedTasks = [];
					this.showToast('query.success.bulkDelete', '', 'success');
				} else {
					throw new QueryError(data.bulkDeleteTasks.error);
				}
			} catch (err) {
				let errType = 'FallbackError';
				if (err instanceof QueryError) {
					errType = err.cause.type;
				}
				this.showToast(`query.errors.${errType}`, '', 'danger');
			}
		},
		async handleAction(value, close) {
			if (value === 'bulkCopy') await this.bulkCopy();
			if (value === 'bulkArchive') await this.bulkArchive();
			if (value === 'bulkRestore') await this.bulkRestore();
			if (value === 'bulkDelete') {
				this.showConfirm = true;
			}
			close();
		},
	},
	computed: {
		taskCount() {
			return this.tasks.filter(el => el.company?.id === this.companyId).length;
		},
		items() {
			const filters = this.filters;
			const selectedTags = this.selectedTags;
			const tasks = this.tasks;
			const searchTask = this.searchTask.toLowerCase();

			return tasks.filter(function (item) {
				if (!filters.draft && item.isDraft) {
					return false;
				}
				if (!filters.published && item.isPublished) {
					return false;
				}
				if (!filters.archived && item.isArchived) {
					return false;
				}
				if (!filters.licenced && item.availableFrom === 'LICENCE') {
					return false;
				}
				if (!filters.company && item.availableFrom === 'COMPANY') {
					return false;
				}
				if (selectedTags.length) {
					if (!selectedTags.every(tag => item.tags.some(t => t.id === tag.id))) {
						return false;
					}
				}
				if (searchTask.length) {
					if (!item.name.toLowerCase().includes(searchTask)) {
						return false;
					}
				}
				return true;
			});
		},
		filtersI18n() {
			return {
				locale: this.$i18n.locale,
				messages: this.$i18n.getLocaleMessage(this.$i18n.locale).filters,
			};
		},
		canMakeActions() {
			return this.canAddTask || this.trashTask || this.canDeleteTask;
		},
		displayedActions() {
			const selected = this.selectedTasks;
			return this.actions.filter(function (action) {
				if (!selected.length) {
					return true;
				}
				if (action.value === 'bulkCopy') {
					return this.canAddTask && selected.length + this.taskCount <= this.taskLimit;
				}
				if (action.value === 'bulkArchive') {
					return selected.every(t => t.isPublished && this.canArchiveTask(t.author.id));
				}
				if (action.value === 'bulkRestore') {
					return selected.every(t => t.isArchived && this.canArchiveTask(t.author.id));
				}
				if (action.value === 'bulkDelete') {
					return selected.every(t => this.canDeleteTask(t.author.id) && !t.isPublished && t.isUnused);
				}
				return true;
			}, this);
		},
		tagsList() {
			return this.tasks.reduce(function (acc, task) {
				if (!task.tags?.length) {
					return acc;
				}
				const tags = task.tags.map(t => ({ ...t })).filter(t => !acc.some(a => a.id === t.id));
				acc.push(...tags);
				return acc;
			}, []);
		},
		...mapState('auth', {
			companyId: state => state.company.id,
			isSuperuser: state => state.user.isSuperuser,
			isStaff: state => state.user.isStaff,
		}),
		...mapGetters('auth', ['canManageTasks']),
	},
	apollo: {
		tasks: {
			query: TASKS,
			manual: true,
			notifyOnNetworkStatusChange: true,
			result({ loading, data, errors }) {
				if (!loading) {
					this.taskLimit = data.myCompany.taskLimit;
					this.tasks = [
						...data.myCompany.tasks.map(el => ({
							...el,
							author: {
								...el.author,
								email: el.author.email === 'PUBLISHER' ? this.$t('publisher') : el.author.email,
							},
						})),
					];
					this.tags = [...data.myCompany.tags];
				}
			},
		},
	},
	components: {
		Pagination,
		DataTable,
		Dropdown,
		DropdownList,
		ModalTaskData,
		ModalAddTask,
		ModalConfirm,
		Spinner,
		TagList,
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.tasks-page {
	position: relative;

	.task-icon {
		width: 1.75rem;
		height: 1.75rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		padding-bottom: 0.25rem;

		&--is-foreign {
			background-color: $warning;
		}
	}

	.alert-manage-tasks {
		display: flex;
		gap: 1rem;
	}

	&__placeholder {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;
		text-align: center;
		color: $secondary;
	}

	&__table-header {
		display: grid;
		grid-template-columns: max-content auto;
		grid-template-rows: auto auto;
		align-items: flex-end;
		gap: 0.5rem;

		.task-dropdowns {
			display: flex;
			gap: 1rem;
		}

		.task-count-limit {
			justify-self: flex-end;

			span:first-of-type {
				margin-right: 0.5rem;
			}
		}

		.task-add-btn {
			grid-column: 2/-1;
			justify-self: flex-end;
		}
	}

	.task-name {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		& > span {
			width: 100%;
		}
	}

	.icon-true {
		font-size: 1.25rem;
		color: $success;
	}

	.icon-false {
		font-size: 1.25rem;
		color: $danger;
	}
}

.search-task-by-name {
	max-width: 14.375rem;
}
.task-search-and-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>
