import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client/core';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createApolloProvider } from '@vue/apollo-option';

const httpLink = new HttpLink({
	uri: process.env.VUE_APP_API_URL,
	credentials: 'include',
});

const batchHttpLink = new BatchHttpLink({
	uri: process.env.VUE_APP_API_URL,
	credentials: 'include',
	batchDebounce: true,
	batchMax: 99,
});

const link = split(operation => operation.getContext().noBatch, httpLink, batchHttpLink);

const cache = new InMemoryCache({
	typePolicies: {
		AssignedTask: {
			keyFields: ['id', 'progressId'],
		},
		AssignmentPartForUser: {
			keyFields: ['id', 'assignmentForUserId'],
		},
	},
});

const apolloClient = new ApolloClient({
	link,
	cache,
});

const apolloProvider = createApolloProvider({
	defaultClient: apolloClient,
});

export default apolloProvider;

export { apolloClient as apollo };
