export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязка устройств"])},
      "dropdown": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензия"])}
      },
      "instruction": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструкция по привязке новых устройств"])},
        "list": {
          "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите в выпадающем списке лицензию и укажите в поле ввода желаемое количество кодов активации. Нажмите кнопку \"Создать\", чтобы получить коды активации."])},
          "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На устройстве которое вы хотите привязать к лицензии зайдите в приложение \"VR Chemistry Lab\". Нажмите на кнопку \"Активировать\" на экране приветствия и введите код активации."])}
        },
        "note": {
          "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Для привязки одного устройства к лицензии требуется один ключ активации."])},
          "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["**Коды активации обладают ограниченным временем жизни (15 минут). Для того, чтобы получить актуальный список кодов активации для конкретной лицензии, нажмите кнопку \"Обновить\"."])},
          "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["***Если срок жизни кода активации подошёл к концу, и код не был использован, то вы можете выпустить вместо него новый код."])}
        }
      },
      "refreshBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
      "endedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["действует до:"])},
      "licenceInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О ЛИЦЕНЗИИ"])},
      "activationCodesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КОДЫ АКТИВАЦИИ"])},
      "fields": {
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лицензионный ключ"])},
        "devicesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["устройств"])},
        "availableKeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["можно создать кодов"])}
      },
      "codesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во кодов активации"])},
      "createActivationCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
      "errors": {
        "ValueOutOfBounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превышено допустимое кол-во кодов активации"])}
      }
    }
  })
}
