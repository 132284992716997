export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find role"])},
      "defaultRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default role"])},
      "createRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new role"])},
      "roleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role name"])},
      "deleteRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete role"])},
      "setDefaultRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as default"])},
      "saveRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save role"])},
      "defaultRoleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New role"])},
      "confirmDeleteHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning!"])},
      "confirmDeleteBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this role?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
      "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role saved."])},
      "setDefaultSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default role set."])},
      "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role removed."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
      "errors": {
        "NameTooLongError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum name length of 100 characters exceeded."])},
        "EmptyValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter role name."])},
        "UniqueValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A role with this name already exists."])},
        "UnauthorizedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not logged in."])},
        "UserPermissionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to perform this action."])},
        "NotFoundError": {
          "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation not found."])},
          "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role not found."])},
          "Permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission not found."])}
        },
        "CompanyMismatchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A role from another organisation can not be the target of this action."])},
        "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])}
      }
    }
  })
}
