export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "statuses": {
        "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
        "ARCHIVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])}
      },
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration date"])},
      "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin-code"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
      "addOneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
      "addManyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load users from file"])},
      "editOneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit user"])},
      "editManyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit users"])},
      "archiveOneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive user"])},
      "archiveManyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive users"])},
      "unarchiveOneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unarchive user"])},
      "unarchiveManyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unarchive users"])},
      "restoreOneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore user"])},
      "restoreManyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore users"])},
      "deleteOneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
      "deleteManyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete users"])},
      "filters": {
        "showActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show active users"])},
        "showInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show inactive users"])},
        "showArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show archived users"])}
      },
      "userLimitMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Users in organisation: ", _interpolate(_named("userCount")), "/", _interpolate(_named("userLimit")), "."])},
      "toastSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action successful"])},
      "bulkCreateSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Added new users: ", _interpolate(_named("emails"))])},
      "bulkArchiveSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Archived users: ", _interpolate(_named("emails"))])},
      "bulkRestoreSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Restored users: ", _interpolate(_named("emails"))])},
      "bulkDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users were deleted"])},
      "updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User successfully updated"])},
      "bulkUpdateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users successfully updated"])},
      "toastError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
      "errors": {
        "UserPermissionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to perform this action."])},
        "NotFoundError": {
          "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not found."])},
          "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation not found."])},
          "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of user roles not found."])},
          "Region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region not found"])}
        },
        "UnauthorizedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not logged in."])},
        "CompanyMismatchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A user from another organisation can not be the target of this action."])},
        "UnexpectedLengthError": {
          "pin": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid pin-code: got ", _interpolate(_named("length")), " digits instead of ", _interpolate(_named("expected")), "."])},
          "password": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid password: password must not have less than ", _interpolate(_named("expected")), " characters (currently ", _interpolate(_named("length")), ")."])}
        },
        "UnexpectedSymbolError": {
          "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid pin-code: code must not contain non-numeric characters."])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password: password contains forbidden characters."])}
        },
        "InvalidValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must not match user email."])},
        "UniqueValueError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Email ", _interpolate(_named("value")), " already used by another user."])},
        "EmptyValueError": {
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User email address must be provided."])}
        },
        "UserIsOwnerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An organisation's owner can not be the target of this action."])},
        "UserIsSelfError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current user can not be the target of this action."])},
        "EntityProtectedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User can not be removed. Delete the user first."])},
        "EntityLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User limit exceeded."])},
        "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please, try again later."])}
      }
    }
  })
}
