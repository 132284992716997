export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "emptyTablePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not own any licences yet"])},
      "noContentPacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["---"])},
      "filters": {
        "showValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active licences"])},
        "showInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive licences"])}
      },
      "licencesTable": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences list"])},
        "headers": {
          "contentPacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content packs"])},
          "endedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available until"])},
          "isValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "devicesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])}
        }
      },
      "activateLicence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate licence"])}
    }
  })
}
