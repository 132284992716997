<template>
	<vr-modal v-model:show="modalShow" size="medium" align="center" :footer-line="false">
		<template v-slot:header> {{ $t('modalLicenceData.title') }} </template>
		<template v-slot:body>
			<div
				:class="[
					'alert',
					{ 'alert-danger': licenceData.isExpired || !licenceData.isActive || licenceData.isBlocked },
					{
						'alert-warning': licenceData.isPending,
						'alert-success': licenceData.isValid,
					},
				]"
				role="alert"
			>
				<h6>{{ licenceData.status }}</h6>
			</div>
			<page-section :title="$t('modalLicenceData.sections.common')">
				<page-section-field
					v-for="(key, idx) in licenceModalKeys.filter(el => el !== 'status' && el !== 'contentPacks')"
					:key="`licence-modal-field-${idx}`"
					:label="$t(`modalLicenceData.fields.${key}`)"
					:value="licenceData[key]"
				/>
			</page-section>
			<page-section
				v-if="licenceData.contentPacks && licenceData.contentPacks.length"
				:title="$t('modalLicenceData.sections.contentPacks')"
			>
				<template v-for="(pack, packIdx) in licenceData.contentPacks" :key="`content-pack-${packIdx}`">
					<span class="badge badge-pill-title bg-secondary">{{ pack.name }}</span>

					<template
						v-for="(entities, entitiesIdx) in [{ tasks: pack.tasks }, { assignments: pack.assignments }]"
						:key="`content-pack-${packIdx}-entity-${entitiesIdx}`"
					>
						<page-section-list
							:title="$t(`modalLicenceData.fields.${Object.keys(entities)[0]}`)"
							:list="getNamesList(entities)"
						/>
					</template>
				</template>
			</page-section>
			<page-section v-if="licenceTasks && licenceTasks.length" :title="$t('modalLicenceData.sections.tasks')">
				<page-section-list :list="licenceTasks.map(t => t.name)" />
			</page-section>
			<page-section
				v-if="licenceData.assignments && licenceData.assignments.length"
				:title="$t('modalLicenceData.sections.assignments')"
			>
				<page-section-list :list="licenceData.assignments" />
			</page-section>
		</template>
	</vr-modal>
</template>

<script>
import VrModal from '@/components/modals/VrModal.vue';
import PageSection from '@/components/page-sections/PageSection.vue';
import PageSectionField from '@/components/page-sections/PageSectionField.vue';
import PageSectionList from '@/components/page-sections/PageSectionList.vue';
import { dateTimeMixin } from '@/mixins';

export default {
	emits: ['update:show'],
	mixins: [dateTimeMixin],
	data() {
		return {
			licenceModalKeys: [
				'status',
				'startedAt',
				'endedAt',
				'devicesNumber',
				'maxDevicesNumber',
				'key',
				'contentPacks',
			],
		};
	},
	methods: {
		getNames(entity) {
			return entity.map(el => el.name);
		},
		getNamesList(entities) {
			return entities[Object.keys(entities)[0]].map(el => el.name);
		},
		getLicenceStatus(licence) {
			if (!licence.isActive) return this.$t('modalLicenceData.status.notActive');
			if (licence.isValid) return this.$t('modalLicenceData.status.isValid');
			if (licence.isBlocked) return this.$t('modalLicenceData.status.isBlocked');
			if (licence.isPending) return this.$t('modalLicenceData.status.isPending');
			if (licence.isExpired) return this.$t('modalLicenceData.status.isExpired');
		},
	},
	computed: {
		modalShow: {
			get() {
				return this.show;
			},
			set(show) {
				this.$emit('update:show', show);
			},
		},
		licenceData() {
			let licence = { ...this.licence } || {};
			if (!(Object.keys(licence).length === 0)) {
				licence.status = this.getLicenceStatus(licence);
				licence.createdAt = this.formatDate(licence.createdAt);
				licence.startedAt = this.formatDate(licence.startedAt);
				licence.endedAt = this.formatDate(licence.endedAt);
				licence.devicesNumber = licence.maxDevicesNumber - licence.availableDevicesNumber;
				licence.tasks = this.getNames(licence.tasks);
				licence.assignments = this.getNames(licence.assignments);
			}
			return licence;
		},
		licenceTasks() {
			return this.licence.assignments?.reduce(function (acc, assignment) {
				if (!assignment.tasks?.length) {
					return acc;
				}
				const tasks = assignment.tasks.filter(t => !acc.some(a => a.id === t.id));
				acc.push(...tasks);
				return acc;
			}, [...this.licence.tasks]);
		},
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		licence: {
			type: Object,
			default: () => ({}),
		},
	},
	components: {
		VrModal,
		PageSection,
		PageSectionField,
		PageSectionList,
	},
};
</script>

<i18n locale="ru" src="@/locales/ru/components/modals/modal-licence-data.json"></i18n>
<i18n locale="en" src="@/locales/en/components/modals/modal-licence-data.json"></i18n>

<style lang="scss" scoped>
.badge-pill-title {
	font-size: 1rem;
	margin-bottom: 1rem;
	&:not(:first-of-type) {
		margin-top: 1.5rem;
	}
}
</style>
