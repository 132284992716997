export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "labels": {
        "assignmentLimitReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your organisation has reached its assignment template limit. To create new templates either remove exising ones or contact support to increase the limit."])},
        "assignmentIsForeign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This assignment template is available via a licence and can not be changed except for adding or removing tags."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name"])},
        "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered"])},
        "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template region"])},
        "availableTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available tasks"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
        "filterRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
        "regionAny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any region"])},
        "filterPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])},
        "platformAny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any"])},
        "filterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "tasksSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected tasks"])},
        "addGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group"])},
        "deleteTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete template"])},
        "deleteTemplateHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not delete an assigned template."])},
        "createTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create template"])},
        "createTemplateHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not create template: organisation limit reached."])},
        "editTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit template"])},
        "editLocalTemplateHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not edit an assigned template."])},
        "editForeignTemplateHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not edit a template available via licence."])},
        "assignTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign to users"])},
        "assignTemplateHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the template before assigning it."])},
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
        "toastError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
        "toastSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "editSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment template changed."])},
        "createSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment template created."])},
        "confirmDeleteHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning!"])},
        "confirmDeleteBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this assignment template?"])},
        "talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked to a Talent course"])}
      },
      "errors": {
        "NoNameError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment template must have a name."])},
        "NotFoundError": {
          "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not found."])},
          "Region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region not found."])},
          "Assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment not found."])},
          "Task": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Task with ID ", _interpolate(_named("pattern")), " not found."])}
        },
        "UserPermissionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to perform this action."])},
        "UserIsNotAdminError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must be an administrator to create assignment templates that are not linked to an organisation."])},
        "ForbiddenError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can not change an assignment template that has been assigned."])},
        "InconsistentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks in assignment template can not have different platforms."])},
        "UniqueValueError": {
          "Assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An assignment template with the same name already exists within the organisation."])},
          "AssignmentPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two groups within the template have the same sequence number."])}
        },
        "UnpublishedTaskUsageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more tasks in the template have not been published."])},
        "ArchivedTaskUsageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more tasks in the tempalte have been archived."])},
        "NotAvailableTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more tasks in the template are unavailable for your organisation."])},
        "EntityLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation assignment template limit reached."])},
        "RequestError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request to create a Talent course failed. Please try again later."])},
        "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])}
      }
    }
  })
}
