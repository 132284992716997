export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "createTaskBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create task"])},
      "emptyTablePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have any tasks yet"])},
      "cantManageTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your organisation does not have permission to manage tasks. To be able to manage tasks your organisation must have an active licence with this option enabled."])},
      "taskLimitReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your organisation can not create any more tasks as it has reached its limit. You can either remove existing tasks or contact support to have the limit increased."])},
      "tagsFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by tags"])},
      "searchTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by task's name"])},
      "taskCountLimit": {
        "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tasks in organisation: ", _interpolate(_named("taskCount")), "/", _interpolate(_named("taskLimit")), "."])},
        "tips": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of all tasks created by this organisation, including those that were deleted, but not removed."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch with support to increase task limit."])}
        }
      },
      "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vics studio"])},
      "filters": {
        "licenced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From licence"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From organisation"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])}
      },
      "actions": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--- Action ---"])},
        "bulkCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
        "bulkArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
        "bulkRestore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])},
        "bulkDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
      },
      "taskTable": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task list"])},
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
          "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])}
        },
        "items": {
          "statuses": {
            "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["draft"])},
            "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["published"])},
            "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archived"])}
          }
        }
      },
      "query": {
        "success": {
          "bulkCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks copied successfully."])},
          "bulkArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks archived successfully."])},
          "bulkRestore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks restored successfully."])},
          "bulkDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks deleted successfully."])}
        },
        "errors": {
          "NotFoundError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of the selected tasks was not found."])},
          "CompanyTaskManageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your organisation does not have permission for this action."])},
          "CompanyMismatchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A task from another organisation can not be the target of this action."])},
          "UserPermissionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to perform this action."])},
          "TrashingEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some selected tasks can not be deleted. They are already used in organisation assignments."])},
          "DeletingEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some selected tasks can not be removed. They must be deleted first."])},
          "UniqueValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task with the same name already exists."])},
          "EntityLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation task limit exceeded."])},
          "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])}
        }
      },
      "bulkDeleteConfirm": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove tasks"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected tasks will be removed. To continue press \"Confirm\"."])}
      }
    }
  })
}
