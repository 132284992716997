export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "modalLicenceData": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о лицензии"])},
        "status": {
          "notActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензия деактивирована"])},
          "isValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензия активна"])},
          "isPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензия ожидает срока начала действия"])},
          "isExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензия истекла"])},
          "isBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензия заблокирована"])}
        },
        "sections": {
          "common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["общее"])},
          "contentPacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["контент паки"])},
          "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["задачи"])},
          "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["шаблоны заданий"])}
        },
        "fields": {
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["статус"])},
          "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ключ"])},
          "startedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["действует с"])},
          "endedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["действует до"])},
          "maxDevicesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["максимальное кол-во устройств"])},
          "devicesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прикреплено устройств"])},
          "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["задачи"])},
          "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["шаблоны заданий"])}
        }
      }
    }
  })
}
