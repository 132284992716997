<template>
	<div class="page-content licences-page">
		<spinner :show="$apollo.loading" />
		<h5 v-if="!licences.length && !$apollo.loading" class="display-5 licences-page__placeholder">
			{{ $t('emptyTablePlaceholder') }}
		</h5>
		<data-table :headers="headers" :items="items" @activateItem="openLicenceData">
			<template #header>
				<div class="licences-page__table-header-left">
					<dropdown-list
						icon="fa-solid fa-filter"
						:list="Object.keys(filters)"
						v-model:selection="filters"
						:i18n="filtersI18n"
					/>
				</div>
			</template>
			<template #item.isValid="{ item }">
				<fa-icon v-if="item.isValid" icon="fa-regular fa-circle-check" class="icon-true" />
				<fa-icon v-else icon="fa-regular fa-circle-xmark" class="icon-false" />
			</template>
			<template #item.devicesNumber="{ item }">
				<button
					@click.stop="openDevicesList(item.id)"
					class="btn btn-outline-secondary licences-page__table__btn"
				>
					{{ item.devicesNumber }}
				</button>
			</template>
			<template #footer>
				<button v-if="canActivateLicence" @click.stop="openAddLicence" class="btn btn-success">
					{{ $t('activateLicence') }}
				</button>
			</template>
		</data-table>
		<modal-licence-data v-model:show="showLicenceData" :licence="openedLicence" />
		<modal-add-licence v-model:show="showAddLicence" @licenceAdded="addLicence" />
		<modal-devices-list v-model:show="showDevicesList" :devices="openedLicence.devices" />
	</div>
</template>

<script>
import MYCOMPANY from '@/queries/views/licences/query-company-licences.graphql';
import DataTable from '@/components/DataTable.vue';
import DropdownList from '@/components/dropdowns/DropdownList.vue';
import ModalLicenceData from '@/components/modals/modalLicenceData.vue';
import ModalAddLicence from '@/components/modals/ModalAddLicence.vue';
import ModalDevicesList from '@/components/modals/ModalDevicesList.vue';
import Pagination from '@/components/Pagination.vue';
import Spinner from '@/components/Spinner.vue';

import { dateTimeMixin } from '@/mixins';
import licencePermissionsMixin from '@/mixins/permissions/licencePermissions.js';

export default {
	mixins: [dateTimeMixin, licencePermissionsMixin],

	data() {
		return {
			licences: [],
			openedLicence: {},
			displayedLicences: [],
			showLicenceData: false,
			showAddLicence: false,
			showDevicesList: false,
			filters: {
				showValid: true,
				showInvalid: true,
			},
			headers: [
				{ text: this.$t('licencesTable.headers.contentPacks'), field: 'contentPacks', type: 'string' },
				{ text: this.$t('licencesTable.headers.endedAt'), field: 'endedAt', type: 'dateTime' },
				{ text: this.$t('licencesTable.headers.isValid'), field: 'isValid', type: 'boolean' },
				{ text: this.$t('licencesTable.headers.devicesNumber'), field: 'devicesNumber' },
			],
		};
	},
	methods: {
		getNames(entity) {
			return entity.map(el => el.name);
		},
		openLicenceData(index) {
			this.openedLicence = this.licences.find(el => el.id === index);
			this.showLicenceData = true;
		},
		openDevicesList(index) {
			this.openedLicence = this.licences.find(el => el.id === index);
			this.showDevicesList = true;
		},
		openAddLicence() {
			this.showAddLicence = true;
		},
		getPacksString(packs) {
			return packs.join(', ');
		},
		addLicence(licence) {
			this.licences.push({ ...licence });
		},
	},
	computed: {
		items() {
			const filters = this.filters;
			return this.licences
				.map(el => ({
					...el,
					contentPacks: this.getPacksString(this.getNames(el.contentPacks)) || this.$t('noContentPacks'),
					endedAt: this.formatDate(el.endedAt),
					devicesNumber: `${el.maxDevicesNumber - el.availableDevicesNumber} (${el.maxDevicesNumber})`,
				}))
				.filter(function (item) {
					if (!filters.showValid && item.isValid) {
						return false;
					}
					if (!filters.showInvalid && !item.isValid) {
						return false;
					}
					return true;
				});
		},
		filtersI18n() {
			return {
				locale: this.$i18n.locale,
				messages: this.$i18n.getLocaleMessage(this.$i18n.locale).filters,
			};
		},
	},
	components: {
		DataTable,
		DropdownList,
		ModalLicenceData,
		ModalAddLicence,
		Pagination,
		ModalDevicesList,
		Spinner,
	},
	apollo: {
		myCompany: {
			query: MYCOMPANY,
			manual: true,
			fetchPolicy: 'cache-and-network',
			result({ loading, data, errors }) {
				if (!loading) {
					this.licences = [...data.myCompany.licences];
				}
			},
		},
	},
};
</script>

<i18n locale="ru" src="@/locales/ru/views/licences.json"></i18n>
<i18n locale="en" src="@/locales/en/views/licences.json"></i18n>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.licences-page {
	position: relative;

	&__placeholder {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;
		text-align: center;
		color: $secondary;
	}

	&__table {
		&__btn {
			position: relative;
			z-index: 2;
		}
	}

	.icon-true,
	.icon-false {
		position: absolute;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
	}

	.icon-true {
		font-size: 1.25rem;
		color: $success;
	}

	.icon-false {
		font-size: 1.25rem;
		color: $danger;
	}
}
</style>
