export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка данных..."])},
        "attemptsOf": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Попытки пользователя ", _interpolate(_named("email"))])},
        "startedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время начала"])},
        "endedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время окончания"])},
        "deleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
        "editGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])}
      },
      "en": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
        "attemptsOf": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Attempts of user ", _interpolate(_named("email"))])},
        "startedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
        "endedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
        "deleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "editGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
      }
    }
  })
}
