<template>
	<modal v-model:show="localShow" size="large">
		<template #header>
			<p class="m-0">{{ $apollo.loading ? $t('loading') : group?.assignment.name }}</p>
			<p class="m-0 fs-6" v-show="selectedUser">{{ $t('attemptsOf', { email: selectedUser?.email }) }}</p>
		</template>
		<template v-if="!$apollo.loading" #body>
			<table class="table table-borderless data-table">
				<tbody>
					<tr>
						<th scope="row">{{ $t('startedAt') }}</th>
						<td>{{ formatDateTime(group?.startedAt) }}</td>
					</tr>
					<tr>
						<th scope="row">{{ $t('endedAt') }}</th>
						<td>{{ formatDateTime(group?.endedAt) }}</td>
					</tr>
				</tbody>
			</table>
			<assignment-details-table v-show="!attemptsTableVisible" :group="group" @show-attempts="showAttempts" />
			<attempts-table v-show="attemptsTableVisible" :progress-id="selectedProgressId" />
		</template>
		<template v-else #body>
			<div class="loading-screen">
				<div class="spinner-border"></div>
			</div>
		</template>
		<template #footer>
			<button
				v-show="!$apollo.loading && !attemptsTableVisible"
				v-if="canDeleteAssignmentForUser(group?.author.id)"
				class="control-button btn btn-danger"
				@click.stop="deleteGroup"
			>
				{{ $t('deleteGroup') }}
			</button>
			<router-link
				v-show="!$apollo.loading && !attemptsTableVisible"
				v-if="canChangeAssignmentForUser(group?.author.id)"
				:to="{ name: 'AssignmentUsers', params: { groupId: group?.id } }"
				class="control-button btn btn-success ms-auto me-0"
				>{{ $t('editGroup') }}</router-link
			>
			<button class="btn btn-secondary me-auto" v-show="attemptsTableVisible" @click="showAttempts(null, null)">
				<fa-icon size="sm" icon="fa fa-chevron-left" />
				<span class="ms-2">{{ $t('back') }}</span>
			</button>
		</template>
	</modal>
</template>

<style scoped lang="scss">
.data-table {
	max-width: 30rem;
}

.loading-screen {
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	.spinner-border {
		width: 6rem;
		height: 6rem;
		margin-top: 6rem;
		margin-bottom: 6rem;
	}
}

.control-button {
	min-width: 25%;

	&:first-child {
		margin-right: auto;
	}
}
</style>

<i18n>
	{
		"ru" : {
			"loading": "Загрузка данных...",
			"attemptsOf": "Попытки пользователя { email }",
			"startedAt": "Дата и время начала",
			"endedAt": "Дата и время окончания",
			"deleteGroup": "Удалить",
			"editGroup": "Редактировать",
			"back": "Назад"
		},
		"en": {
			"loading": "Loading...",
			"attemptsOf": "Attempts of user { email }",
			"startedAt": "Start date",
			"endedAt": "End date",
			"deleteGroup": "Delete",
			"editGroup": "Edit",
			"back": "Back"
		}
	}
</i18n>

<script>
import { modalMixin, dateTimeMixin, assignmentForUserPermissionsMixin } from '@/mixins';

import QUERY from '@/queries/views/assignment-list/query-assignment.graphql';

import AssignmentDetailsTable from './AssignmentDetailsTable';
import AttemptsTable from './AttemptsTable';

export default {
	mixins: [modalMixin, dateTimeMixin, assignmentForUserPermissionsMixin],
	emits: ['delete-group'],
	props: {
		groupId: {
			type: String,
			default: null,
		},
	},
	components: {
		AssignmentDetailsTable,
		AttemptsTable,
	},
	data() {
		return {
			group: null,
			selectedProgressId: null,
			selectedUserId: null,
		};
	},
	computed: {
		attemptsTableVisible() {
			return this.selectedProgressId;
		},
		selectedUser() {
			return this.group?.users.find(usr => usr.id === this.selectedUserId);
		},
	},
	methods: {
		showAttempts(progressId, userId) {
			this.selectedProgressId = progressId;
			this.selectedUserId = userId;
		},
		async deleteGroup() {
			this.localShow = false;
			this.$emit('delete-group', this.groupId);
		},
	},
	apollo: {
		group: {
			query: QUERY,
			variables() {
				return { groupId: this.groupId };
			},
			manual: true,
			skip() {
				return !this.groupId;
			},
			result({ data, loading }) {
				if (!loading) {
					this.group = data.assignmentGroup;
				}
			},
		},
	},
	watch: {
		localShow(val) {
			if (!val) {
				this.showAttempts(null, null);
				this.group = null;
			}
		},
	},
};
</script>
